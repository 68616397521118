import React, { FC } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import JobChips from 'components/JobsDashboard/JobChips';
import { JobSalaryText } from 'components/JobsDashboard/job-salary-text';
import { JobInfo } from '../job-info';
import { IJobItemContent } from './job-item-content.interface';
import { jobItemContentStyles } from './job-item-content.styles';

export const JobItemContent: FC<IJobItemContent> = ({
  job: {
    job_role: { name },
    company: {
      city: {
        currency,
        name: city,
        country: { name: country },
      },
      logo_url,
      name: companyName,
    },
    salary_min,
    salary_max,
    salary_range_hidden,
    salary_match,
    employment_types,
    employment_levels,
    experience_years_max,
    experience_years_min,
    skills,
    languages,
    work_model,
    region,
    regions,
  },
  logoDisabled,
}) => {
  const { t } = useTranslate();

  const { filtersValues } = useFiltersValues();

  return (
    <Box sx={jobItemContentStyles.container}>
      {!logoDisabled && (
        <Box sx={jobItemContentStyles.companyContainer}>
          {/* Logo */}
          <Box sx={jobItemContentStyles.logo}>
            <Image
              src={logo_url}
              fill
              alt={`${t('img.logoOf')} ${companyName}`}
              title={`${t('img.logoOf')} ${companyName}`}
              style={{
                objectPosition: 'left',
                objectFit: 'contain',
              }}
            />
          </Box>
          {/* Company name */}
          <Typography
            variant="body2"
            component="h3"
            color="textSecondary"
            sx={jobItemContentStyles.companyName}
          >
            {companyName}
          </Typography>
        </Box>
      )}

      <Box sx={jobItemContentStyles.titleContainer} component="header">
        {/* Name */}
        <Typography
          variant="h2"
          component="h2"
          sx={jobItemContentStyles.nameText}
        >
          {name}
        </Typography>

        {/* Salary */}
        <Box sx={jobItemContentStyles.salaryText}>
          <JobSalaryText
            userSalaryMin={filtersValues.salary_min}
            userSalaryMax={filtersValues.salary_max}
            userCountry={filtersValues.country_id}
            jobSalaryMin={salary_min}
            jobSalaryMax={salary_max}
            jobCurrency={currency}
            jobSalaryRangeHidden={!!salary_range_hidden}
            jobSalaryMatch={!!salary_match}
            jobEmploymentTypes={employment_types}
            netTextSx={jobItemContentStyles.netText}
          />
        </Box>
      </Box>

      {/* Chips */}
      <Box sx={jobItemContentStyles.chipsContainer}>
        <JobChips blackItems={skills} greenItems={languages} />
      </Box>

      {/* Job info */}
      <Box sx={jobItemContentStyles.infoContainer}>
        <JobInfo
          employmentTypes={employment_types}
          employmentLevels={employment_levels}
          experienceYearsMin={experience_years_min}
          experienceYearsMax={experience_years_max}
          city={city}
          country={country}
          workModel={work_model}
          region={region}
          regions={regions}
        />
      </Box>
    </Box>
  );
};

JobItemContent.defaultProps = {
  logoDisabled: false,
};

export default JobItemContent;
