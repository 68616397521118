import React, { FC, memo, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useDebounce } from '@nploy/shared';
import { IJobAutocompleteSearch } from './job-autocomplete-search.interface';

export const JobAutocompleteSearch: FC<IJobAutocompleteSearch> = memo(
  ({
    inputPlaceholder,
    placeholder,
    options,
    styles,
    value,
    onChange,
  }: IJobAutocompleteSearch) => {
    const [inputValue, setInputValue] = useState(value);
    const debouncedVal = useDebounce(inputValue, 600);

    useEffect(() => {
      onChange(debouncedVal);
    }, [debouncedVal]);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <Autocomplete
        value={inputValue || ''}
        onChange={(_, newValue: string | null) => {
          setInputValue(newValue);
        }}
        placeholder={placeholder}
        fullWidth
        inputValue={inputValue || ''}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        sx={styles}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} placeholder={inputPlaceholder} />
        )}
      />
    );
  },
);
