import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const useJobStatusChipStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: -8,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      top: 0,
      height: '100%',
      flexDirection: 'column',
    },
  },
  denseContainer: {
    top: -10,
  },
  chip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(25),
      transform: 'translate(-50%, 0) rotate(270deg)',
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  denseChip: {
    height: 26,
    [theme.breakpoints.up('md')]: {
      height: 32,
    },
  },
  warning: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.warning.main,
    '&:focus': {
      backgroundColor: theme.palette.warning.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  error: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.error.main,
    '&:focus': {
      backgroundColor: theme.palette.error.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  denseLabel: {
    fontSize: '0.625rem',
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2.5),
    },
  },
}));
