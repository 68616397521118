import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SimpleChip } from '@nploy/ui/web';
import classnames from 'classnames';
import { IJobStatusChip } from './job-status-chip.interface';
import { useJobStatusChipStyles } from './job-status-chip.styles';

export const StatusChip: FC<IJobStatusChip> = ({
  color,
  label,
  dense,
  onClick,
  title,
}) => {
  const classes = useJobStatusChipStyles();

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };
  return (
    <Box
      className={classnames(classes.container, dense && classes.denseContainer)}
    >
      <SimpleChip
        label={label}
        variant="default"
        onClick={handleClick}
        className={classnames(
          classes.chip,
          dense && classes.denseChip,
          color === 'warning' && classes.warning,
          color === 'error' && classes.error,
        )}
        title={title}
        labelClassName={dense ? classes.denseLabel : ''}
        ml={undefined}
        mt={undefined}
        mr={undefined}
        mb={undefined}
      />
    </Box>
  );
};

StatusChip.defaultProps = {
  color: 'primary',
  dense: false,
};

export default StatusChip;
