import theme from 'theme/theme';
import { BORDER_WIDTH } from 'constants/job';

export const jobStatusStyles = {
  borderCommon: {
    position: 'absolute',
    borderRadius: theme.spacing(1),
  },
  borderLeft: {
    top: -BORDER_WIDTH,
    left: -BORDER_WIDTH,
    height: 20,
    width: `calc(100% + ${BORDER_WIDTH * 2}px)`,
    borderTopStyle: 'solid',
    borderTopWidth: BORDER_WIDTH,
    [theme.breakpoints.up('md')]: {
      height: `calc(100% + ${BORDER_WIDTH * 2}px)`,
      width: 20,
      top: -BORDER_WIDTH,
      borderTopStyle: 'none',
      borderLeftStyle: 'solid',
      borderLeftWidth: BORDER_WIDTH,
    },
  },
  noMatch: {
    borderTopColor: theme.palette.error.main,
    [theme.breakpoints.up('md')]: { borderLeftColor: theme.palette.error.main },
  },
  reviewed: {
    borderTopColor: theme.palette.warning.main,
    [theme.breakpoints.up('md')]: {
      borderLeftColor: theme.palette.warning.main,
    },
  },
  matchRequest: {
    top: -BORDER_WIDTH,
    left: -BORDER_WIDTH,
    height: `calc(100% + ${BORDER_WIDTH * 2}px)`,
    width: `calc(100% + ${BORDER_WIDTH * 2}px)`,
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH,
    borderColor: theme.palette.primary.main,
  },
};
