import theme from 'theme/theme';

export const jobInfoStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 16,
    width: 16,
    marginRight: theme.spacing(1),
  },
  text: {
    fontSize: '0.875rem',
  },
  textSecondary: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
};
