import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import theme from 'theme/theme';

export const useJobUnmatchFeedbackStyles = makeStyles(() => ({
  container: {
    backgroundColor: palette.behance.blueTrust,
    position: 'absolute',
    borderRadius: 10,
    padding: 10,
    maxWidth: 'none',
    width: '101%',
    maxHeight: '90%',
    bottom: '-1px',
    left: '-2px',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      height: '101%',
      right: '-2px',
      top: '-2px',
      padding: 20,
      left: 'auto',
      maxHeight: 'initial',
    },
  },
  text: {
    color: theme.palette.common.white,
    fontSize: theme.spacing(1.6),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
