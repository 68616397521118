import React, { FC, useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { animated, useSpring } from 'react-spring';
import { Box, useMediaQuery } from '@mui/material';
import theme from 'theme/theme';
import { SkeletonJobItem } from 'components/JobsDashboard';
import { AnimationJobItem } from '../animation-job-item';
import { NoJobs } from '../no-jobs';
import { IJobsList } from './jobs-list.interface';
import { jobsListStyles } from './jobs-list.styles';

const AnimatedBox = animated(Box);

export const JobsList: FC<IJobsList> = ({
  showDetailsId,
  showingDetails,
  onShowDetails,
  jobs,
  loading,
  onLoadMore,
  hasNextPage,
  controlsDisabled,
  logoDisabled,
  selectedBorderLeft,
  NoJobsComponent,
}) => {
  const isAnimated = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const handleLoadMore = useCallback(() => {
    if (!loading && hasNextPage) {
      onLoadMore();
    }
  }, [loading, hasNextPage, onLoadMore]);

  const showNoJobs = useMemo(() => !jobs?.length && !loading, [jobs, loading]);

  const listTransitionStyle = useSpring({
    flexGrow: showingDetails ? 0 : 1,
    config: { duration: 500 },
  });

  return (
    <>
      {!showNoJobs ? (
        <AnimatedBox
          style={isAnimated ? listTransitionStyle : {}}
          component="section"
          sx={jobsListStyles.list}
        >
          <InfiniteScroll
            loadMore={handleLoadMore}
            hasMore={hasNextPage}
            initialLoad={false}
            style={{ width: '100%', overflow: 'hidden' }}
          >
            <AnimationJobItem
              jobs={jobs}
              onShowDetails={onShowDetails}
              controlsDisabled={controlsDisabled}
              logoDisabled={logoDisabled}
              selectedBorderLeft={selectedBorderLeft}
              showDetailsId={showDetailsId}
              showingDetails={showingDetails}
            />
          </InfiniteScroll>
          {loading && <SkeletonJobItem />}
        </AnimatedBox>
      ) : (
        <NoJobsComponent />
      )}
    </>
  );
};

JobsList.defaultProps = {
  showDetailsId: 0,
  controlsDisabled: false,
  onLoadMore: () => {},
  hasNextPage: false,
  loading: false,
  logoDisabled: false,
  selectedBorderLeft: false,
  NoJobsComponent: NoJobs,
};

export default JobsList;
