import React, { FC, PropsWithChildren, useEffect } from 'react';
import Sticky from 'react-sticky-el';
import { useRouter } from 'next/router';
import {
  Box,
  Container,
  Dialog,
  IconButton,
  Paper,
  Slide,
  useMediaQuery,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { SlideUpModalTransition } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import theme from 'theme/theme';
import { IJobDetailedViewContainer } from './job-detailed-view-container.interface';
import {
  jobDetailedViewContainerStyles,
  useStyles,
} from './job-detailed-view-container.styles';

export const JobDetailedViewContainer: FC<
  PropsWithChildren<IJobDetailedViewContainer>
> = ({
  children,
  showingDetails,
  onCloseDetails,
  desktopSlideDirection,
  desktopContainerSx,
}) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const classes = useStyles();
  const router = useRouter();

  const handleBackButtonClick = () => {
    onCloseDetails();
  };

  const handleCloseDetails = () => {
    onCloseDetails();

    if (
      showingDetails &&
      !isDesktop &&
      window?.location?.hash === '#jobDetails'
    ) {
      router.back();
    }
  };

  useEffect(() => {
    if (showingDetails && !isDesktop) {
      router.push(`${router.asPath.split('#')[0]}#jobDetails`);

      window.addEventListener('hashchange', handleBackButtonClick);
    } else {
      window.removeEventListener('hashchange', handleBackButtonClick);
    }

    return () =>
      window.removeEventListener('hashchange', handleBackButtonClick);
  }, [showingDetails]);

  if (isDesktop) {
    return (
      <Sticky
        topOffset={-24}
        stickyStyle={{ top: 16 }}
        aria-labelledby="open-details-button close-details-button"
      >
        <Slide
          direction={desktopSlideDirection}
          in={!!showingDetails}
          timeout={500}
          className={classes.animationContainer}
        >
          <Box position="relative" component="section">
            <Box
              position="absolute"
              sx={{
                ...jobDetailedViewContainerStyles.contentContainer,
                ...(desktopContainerSx && desktopContainerSx),
              }}
            >
              <Paper
                component="article"
                sx={{
                  ...jobDetailedViewContainerStyles.desktopContentContainer,
                  ...(desktopSlideDirection === 'right' &&
                    jobDetailedViewContainerStyles.desktopContentContainerSlideFromRight),
                }}
                elevation={3}
              >
                {children}
              </Paper>
            </Box>
          </Box>
        </Slide>
      </Sticky>
    );
  }

  return (
    <Dialog
      open={!!showingDetails}
      onClose={handleCloseDetails}
      TransitionComponent={
        SlideUpModalTransition as DialogProps['TransitionComponent']
      }
      hideBackdrop
      fullScreen
      PaperProps={{
        sx: jobDetailedViewContainerStyles.modal,
        id: 'job-details',
        'aria-labelledby': 'open-details-button close-details-button',
      }}
    >
      <Container>
        <Box position="relative" sx={{ zIndex: 2 }} zIndex={2}>
          <IconButton
            onClick={handleCloseDetails}
            sx={jobDetailedViewContainerStyles.closeButton}
          >
            <FontAwesomeIcon
              icon={faTimes as IconProp}
              color={palette.black.main}
              size="sm"
            />
          </IconButton>

          <Box
            component="article"
            sx={jobDetailedViewContainerStyles.mobileContentContainer}
          >
            {children}
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

JobDetailedViewContainer.defaultProps = {
  desktopSlideDirection: 'left',
};
