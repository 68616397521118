import theme from 'theme/theme';

export const jobItemContentStyles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '6fr 4fr',
      gap: `0 ${theme.spacing(2)}`,
    },
  },
  companyContainer: {
    gridRow: 1,
    marginBottom: theme.spacing(1),
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      gridRow: 1,
      gridColumn: 2,
    },
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      gridColumn: 1,
      marginBottom: theme.spacing(3),
    },
  },
  infoContainer: {
    gridRow: 3,
    marginBottom: theme.spacing(2.5),
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      gridRow: 2,
      gridColumn: 2,
      marginBottom: 0,
      marginTop: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  chipsContainer: {
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      gridColumn: 1,
    },
  },
  nameText: {
    marginBottom: theme.spacing(0.5),
  },
  salaryText: {
    fontSize: '0.9375rem',
    fontWeight: 600,
    color: theme.palette.info.main,
  },
  netText: {
    fontSize: 'inherit',
    color: 'inherit',
    fontWeight: 400,
  },
  logo: {
    minHeight: 30,
    maxHeight: 40,
    height: '100%',
    width: 'auto',
    maxWidth: '100%',
    position: 'relative',
    marginBottom: theme.spacing(1),
  },
  companyName: {
    fontSize: '0.875rem',
  },
};
