import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import theme from 'theme/theme';
import { BORDER_WIDTH } from 'constants/job';

const SelectedBorder = ({
  isSelected,
  selectedBorderLeft,
  matchBorderApplied,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classnames(
        classes.selectedBorderHidden,
        matchBorderApplied && classes.selectedBorderHiddenMatched,
        selectedBorderLeft
          ? classes.selectedBorderLeftHidden
          : classes.selectedBorderRightHidden,
        isSelected && classes.selectedBorderShown,
      )}
    />
  );
};

const useStyles = makeStyles(() => ({
  selectedBorderHidden: {
    position: 'absolute',
    top: -BORDER_WIDTH,
    borderRadius: 10,
    height: `calc(100% + ${BORDER_WIDTH * 2}px)`,
    width: 20,
    opacity: 0,
    transition: 'opacity 300ms',
  },
  selectedBorderLeftHidden: {
    left: -BORDER_WIDTH,
    borderLeftStyle: 'solid',
    borderLeftWidth: 4,
    borderLeftColor: theme.palette.primary.main,
  },
  selectedBorderRightHidden: {
    right: -BORDER_WIDTH,
    borderRightStyle: 'solid',
    borderRightWidth: 4,
    borderRightColor: theme.palette.primary.main,
  },
  selectedBorderHiddenMatched: {
    borderRightWidth: 4 + BORDER_WIDTH,
  },
  selectedBorderShown: {
    opacity: 1,
  },
}));

SelectedBorder.propTypes = {
  isSelected: PropTypes.bool,
  selectedBorderLeft: PropTypes.bool,
  matchBorderApplied: PropTypes.bool,
};

SelectedBorder.defaultProps = {
  isSelected: false,
  selectedBorderLeft: false,
  matchBorderApplied: false,
};

export default SelectedBorder;
