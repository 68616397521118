import React, { FC } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import useTranslate from 'utils/i18n/useTranslate';
import { IApplyDiscardButton } from './apply-discard-button.interface';
import { applyDiscardButtonStyles } from './apply-discard-button.styles';

const ApplyIcon = () => (
  <FontAwesomeIcon icon={faCheck as IconProp} color={palette.primary.main} />
);
const DiscardIcon = () => (
  <FontAwesomeIcon icon={faTimes as IconProp} color={palette.error.main} />
);

const Button: FC<IApplyDiscardButton> = ({ discard, onClick, isExternal }) => {
  const { t } = useTranslate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Box
      sx={{
        ...applyDiscardButtonStyles.container,
        ...(discard && applyDiscardButtonStyles.left),
      }}
    >
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: applyDiscardButtonStyles.tooltip,
          },
          arrow: {
            sx: applyDiscardButtonStyles.arrow,
          },
        }}
        title={
          // eslint-disable-next-line no-nested-ternary
          discard
            ? t('job.discard')
            : isExternal
            ? t('job.applyExternal')
            : t('job.apply')
        }
        placement="bottom"
        arrow
      >
        <IconButton sx={applyDiscardButtonStyles.button} onClick={handleClick}>
          {discard ? <DiscardIcon /> : <ApplyIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

Button.defaultProps = {
  discard: false,
};

export const ApplyDiscardButton = React.memo(Button);
