import React, { FC, useMemo } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import { IJobUnmatchFeedback } from './JobUnmatchFeedback.interface';
import { useJobUnmatchFeedbackStyles } from './JobUnmatchFeedback.styles';

export const JobUnmatchFeedback: FC<IJobUnmatchFeedback> = ({
  reason,
  reasonId,
}) => {
  const styles = useJobUnmatchFeedbackStyles();
  const { t } = useTranslate();
  const memoizeTitle = useMemo(
    () => (!reasonId || reasonId === 5 ? reason : t(`job.${reason}`)),
    [reasonId, reason],
  );
  return (
    <Box className={styles.container}>
      <Tooltip title={memoizeTitle}>
        <Typography variant="subtitle2" className={styles.text}>
          {memoizeTitle}
        </Typography>
      </Tooltip>
    </Box>
  );
};
