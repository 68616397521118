import React from 'react';
import { Box, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material';
import theme from 'theme/theme';
import { skeletonJobItemStyles } from './skeleton-job-item.styles';

export const SkeletonJobItem = () => {
  const isAnimated = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      style={{
        transition: isAnimated && `${1000}ms`,
      }}
      sx={skeletonJobItemStyles.container}
      mb={1}
    >
      <Paper sx={skeletonJobItemStyles.card} elevation={0} component="article">
        <Box sx={skeletonJobItemStyles.gridContainer}>
          <Box sx={skeletonJobItemStyles.companyContainer}>
            {/* Logo */}
            <Box mb={1}>
              <Skeleton
                variant="rectangular"
                width={150}
                height={40}
                animation="wave"
              />
            </Box>

            {/* Company name */}
            <Typography
              variant="body2"
              color="textSecondary"
              sx={skeletonJobItemStyles.companyName}
            >
              <Skeleton animation="wave" />
            </Typography>
          </Box>

          <Box sx={skeletonJobItemStyles.titleContainer} component="header">
            {/* Name */}
            <Typography variant="h2" sx={skeletonJobItemStyles.nameText}>
              <Skeleton animation="wave" />
            </Typography>

            {/* Salary */}
            <Typography
              variant="h5"
              sx={skeletonJobItemStyles.salaryText}
              color="secondary"
            >
              <Box mb={1}>
                <Skeleton animation="wave" />
              </Box>
            </Typography>
          </Box>

          {/* Chips */}
          <Box sx={skeletonJobItemStyles.chipsContainer}>
            <Box mb={1}>
              <Skeleton animation="wave" />
            </Box>
            <Box mb={1}>
              <Skeleton animation="wave" />
            </Box>
          </Box>

          {/* Job info */}
          <Box sx={skeletonJobItemStyles.infoContainer}>
            <Box mb={1}>
              <Skeleton animation="wave" />
            </Box>
            <Box mb={1}>
              <Skeleton animation="wave" />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
