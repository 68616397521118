import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const jobDetailedViewContentStyles = {
  actions: {
    width: '100% !important',
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
};

export const useJobDetailedViewContentStyles = makeStyles(() => ({
  desktopScroll: {
    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 200px)',
      // firefox
      scrollbarWidth: 'thin',
      scrollbarColor: theme.palette.success.main,
      // chrome
      /* width */
      '&::-webkit-scrollbar': {
        width: 8,
        zIndex: 0,
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.success.main,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.common.white,
        borderRadius: 8,
      },
    },
  },
}));
