import { makeStyles } from '@mui/styles';
import shadows from 'theme/shadows';
import theme from 'theme/theme';
import { BORDER_WIDTH, JOB_ITEM_WIDTH } from 'constants/job';

export const jobItemStyles = {
  container: {
    width: '100%',
    position: 'relative',
  },
};
export const useJobItemStyles = makeStyles(() => ({
  card: {
    width: '100%',
    padding: `calc(${theme.spacing(3)} - ${BORDER_WIDTH}px)`,
    margin: 'auto',
    position: 'relative',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH,
    borderColor: theme.palette.common.white,
    transition: 'transform 300ms',
    '&:hover': {
      boxShadow: shadows[8],
      cursor: 'pointer',
    },
    [theme.breakpoints.up('sm')]: {
      width: JOB_ITEM_WIDTH,
      maxWidth: '100%',
    },
  },
  isLiking: {
    transform: 'translate(20px)',
  },
  isDisliking: {
    transform: 'translate(-20px)',
  },
  selectedCard: {
    boxShadow: shadows[3],
    transition: 'box-shadow 300ms',
  },
}));
