import React, { FC } from 'react';
import { Box } from '@mui/material';
import { CANDIDATE_UNMATCH_FEEDBACKS } from 'constants/candidate';
import useTranslate from 'utils/i18n/useTranslate';
import { StatusChip } from '../job-status-chip';
import { IJobStatus } from './job-status.interface';
import { jobStatusStyles } from './job-status.styles';

export const JobStatus: FC<IJobStatus> = ({
  matchRequest,
  noMatch,
  profileReviewed,
  onClick,
  reasonId,
}) => {
  const { t } = useTranslate();
  const isRecruiterFeedbackDontWantToSpecify =
    reasonId === CANDIDATE_UNMATCH_FEEDBACKS.dontWantSpecify;
  if (matchRequest)
    return (
      <>
        <Box
          sx={{
            ...jobStatusStyles.borderCommon,
            ...jobStatusStyles.matchRequest,
          }}
        />
        <StatusChip color="primary" label={t('job.matchRequest')} />
      </>
    );

  if (noMatch && !isRecruiterFeedbackDontWantToSpecify)
    return (
      <>
        <Box
          sx={{
            ...jobStatusStyles.borderCommon,
            ...jobStatusStyles.borderLeft,
            ...jobStatusStyles.noMatch,
          }}
        />
        <StatusChip
          color="error"
          label={t('job.noMatchWithFeedback')}
          title={t('job.noMatchWithFeedback')}
          dense
          onClick={onClick}
        />
      </>
    );

  if (profileReviewed || (noMatch && isRecruiterFeedbackDontWantToSpecify))
    return (
      <>
        <Box
          sx={{
            ...jobStatusStyles.borderCommon,
            ...jobStatusStyles.borderLeft,
            ...jobStatusStyles.reviewed,
          }}
        />
        <StatusChip
          color="warning"
          label={
            isRecruiterFeedbackDontWantToSpecify
              ? t('job.noMatch')
              : t('job.profileReviewed')
          }
          dense
        />
      </>
    );

  return null;
};

JobStatus.defaultProps = {
  matchRequest: false,
  noMatch: false,
  profileReviewed: false,
};

export default React.memo(JobStatus);
