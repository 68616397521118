import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';
import { JOB_ITEM_WIDTH } from 'constants/job';

export const jobDetailedViewContainerStyles = {
  modal: {
    width: '100%',
    maxWidth: '100%',
    margin: 0,
    borderRadius: 0,
    height: '100vh',
    maxHeight: '100vh',
    paddingTop: '32px',
  },
  animationContainer: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      width: 0,
      overflow: 'visible',
    },
  },
  closeButton: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      top: '4px',
      right: 0,
      height: '40px',
      width: '40px',
    },
  },
  mobileContentContainer: {
    paddingTop: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '40px',
    zIndex: 1,
  },
  contentContainer: {
    top: 0,
    [theme.breakpoints.up('md')]: {
      width: `calc(100vw - 42px - ${JOB_ITEM_WIDTH}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.breakpoints.values.lg - 42 - JOB_ITEM_WIDTH,
    },
    [theme.breakpoints.up('xl')]: {
      width: theme.breakpoints.values.lg - 42 - JOB_ITEM_WIDTH,
    },
  },
  desktopContentContainer: {
    marginLeft: '24px',
    borderRadius: 1,
  },
  desktopContentContainerSlideFromRight: {
    marginRight: '24px',
    marginLeft: 0,
  },
};

export const useStyles = makeStyles(() => ({
  animationContainer: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      width: 0,
      overflow: 'visible',
    },
  },
}));
