import React, { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Button } from '@nploy/ui/web';
import theme from 'theme/theme';
import {
  createJobPath,
  createShareJobUrl,
} from 'utils/helpers/createShareJobUrl';
import { checkCandidateApproved } from 'utils/helpers/isCandidateApproved';
import useTranslate from 'utils/i18n/useTranslate';
import { useJobDetails } from 'utils/swr/hooks/useJobDetails';
import { useUser } from 'utils/swr/hooks/useUser';
import { useAppDispatch } from 'hooks/redux';
import { setJobDeepLink } from 'store/reducers/jobsReducer';
import { openCreateAccountModal } from 'store/reducers/uiReducer';
import { JobDetailsHeader, ShareJobButton } from 'components/JobDetails';
import JobDetailsBody from 'components/JobDetails/JobDetailsContent/JobDetailsBody/JobDetailsBody';
import { IJobDetailedViewContent } from './job-detailed-view-content.interface';
import {
  jobDetailedViewContentStyles,
  useJobDetailedViewContentStyles,
} from './job-detailed-view-content.styles';

export const JobDetailedViewContent: FC<IJobDetailedViewContent> = ({
  job,
  jobId,
  onCloseDetails,
  onApply,
  onDiscard,
  shouldLoadMoreDetails,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const classes = useJobDetailedViewContentStyles();
  const {
    user: { isAuthed },
  } = useUser();

  const { t, lang } = useTranslate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  // Get jobDetails for shareUrl Link
  const {
    jobDetails,
    mutate,
    loading: detailsLoading,
  } = useJobDetails(jobId, job?.isExternal);

  const {
    description,
    published_at: publishedAt,
    dialog_info: dialogInfo,
  } = jobDetails;

  const isCandidateApproved = checkCandidateApproved(
    dialogInfo?.candidate_job_status_id,
  );
  const handleApply = async () => {
    if (!isAuthed) {
      dispatch(openCreateAccountModal());
    } else {
      if (onApply) {
        await onApply(jobId, job?.apply_link);
        await mutate();
      }
    }
  };

  const handleDiscard = () => {
    onDiscard(jobId, job?.isExternal);
  };

  const shareUrl = createShareJobUrl(jobDetails, lang);

  useEffect(() => {
    if (job) {
      const pathForDeepLink = createJobPath(jobDetails);
      dispatch(setJobDeepLink(pathForDeepLink));
    }
  }, [job]);

  const onApplyOrChatBtn = async () => {
    if (isCandidateApproved) {
      await router.push({
        pathname: `/messages`,
        query: {
          chat: dialogInfo.id,
        },
      });
    } else {
      await handleApply();
    }
  };

  return (
    <>
      {job ? (
        <JobDetailsHeader
          name={job?.job_role?.name}
          salary_range_hidden={!!job?.salary_range_hidden}
          salary_min={job?.salary_min}
          salary_max={job?.salary_max}
          onApply={handleApply}
          currency={job?.company.city.currency}
          onDiscard={onDiscard && handleDiscard}
          onCloseDetails={onCloseDetails}
          shareUrl={shareUrl}
          employmentTypes={job.employment_types}
          isCandidateApproved={isCandidateApproved}
          onApplyOrChat={onApplyOrChatBtn}
        />
      ) : (
        <Skeleton animation="wave" />
      )}

      <JobDetailsBody
        description={job?.description || description}
        detailsLoading={shouldLoadMoreDetails && detailsLoading}
        skills={job?.skills}
        languages={job?.languages}
        benefits={job?.benefits}
        className={classes.desktopScroll}
        dynamicHeightOnMdUp
        publishedAt={publishedAt}
      />

      <Box sx={jobDetailedViewContentStyles.actions}>
        {isMobile && (
          <>
            {(onApply || isCandidateApproved) && (
              <Button fullWidth mb={1} onClick={onApplyOrChatBtn}>
                {isCandidateApproved ? t('job.goToChat') : t('job.apply')}
              </Button>
            )}

            <Box display="flex" width="100%">
              {!!(onDiscard && !isCandidateApproved) && (
                <Button
                  fullWidth
                  flexGrow={1}
                  mr={1}
                  color="paleGrey"
                  onClick={handleDiscard}
                >
                  {t('job.discard')}
                </Button>
              )}

              {shareUrl && <ShareJobButton url={shareUrl} />}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default JobDetailedViewContent;
