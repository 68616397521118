import React, { FC } from 'react';
import { animated, useTransition } from 'react-spring';
import { Box } from '@mui/material';
import { JobItem } from '../JobItem';
import { IAnimationJobItem } from './animation-job-item.interface';

const AnimatedBox = animated(Box);

export const AnimationJobItem: FC<IAnimationJobItem> = ({
  jobs,
  controlsDisabled,
  logoDisabled,
  onShowDetails,
  selectedBorderLeft,
  showDetailsId,
  showingDetails,
}) => {
  const transitions = useTransition(jobs || [], (item) => item?.id, {
    from: { opacity: 0, maxHeight: 0 },
    enter: { opacity: 1, maxHeight: 600 },
    leave: { opacity: 0, maxHeight: 0 },
    config: {
      duration: 600,
    },
  });

  return (transitions || []).map(({ item: job, props, key }) => {
    if (!job) return null;

    return (
      <AnimatedBox style={props} key={`${key}-animation-item`}>
        <JobItem
          job={job}
          showingDetails={showingDetails}
          isSelected={showDetailsId === job?.id && showingDetails}
          onShowDetails={onShowDetails}
          controlsDisabled={controlsDisabled}
          logoDisabled={logoDisabled}
          selectedBorderLeft={selectedBorderLeft}
        />
      </AnimatedBox>
    );
  });
};
