import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Box, Container, Typography } from '@mui/material';
import { Button } from '@nploy/ui/web';
import noJobsIcon from 'public/images/icons/job-list/no-jobs-found.svg';
import useTranslate from 'utils/i18n/useTranslate';
import { noJobsStyles } from './no-jobs.styles';

export const NoJobs = () => {
  const router = useRouter();
  const { t } = useTranslate('home');
  const { t: tImage } = useTranslate();

  const handleNavigateToTopCompanies = () => {
    router.push('/top_companies');
  };

  return (
    <Container sx={noJobsStyles.container} component="section">
      <Box
        pb={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box sx={noJobsStyles.noJobsIcon}>
          <Image
            src={noJobsIcon}
            alt={tImage('img.noJobsFoundAlt')}
            title={tImage('img.noJobsFoundTitle')}
            width={177}
            height={213}
          />
        </Box>
        <Typography
          variant="h5"
          sx={noJobsStyles.noMatchingText}
          color="secondary"
        >
          {t('noJobs.noJobsMatching')}
        </Typography>
        <Typography variant="body2" sx={noJobsStyles.bodyText}>
          {t('noJobs.noAvailableJobs')}
        </Typography>
        <Typography variant="body2" sx={noJobsStyles.bodyText}>
          {t('noJobs.changeYourFilters')}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button onClick={handleNavigateToTopCompanies}>
          {t('noJobs.viewTopCompanies')}
        </Button>
      </Box>
    </Container>
  );
};
