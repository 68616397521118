import React, { FC } from 'react';
import Image from 'next/image';
import { Box, ButtonBase, useMediaQuery } from '@mui/material';
import jobGridIcon from 'public/images/icons/job-list/jobs_grid.svg';
import jobGridIconSelected from 'public/images/icons/job-list/jobs_grid_selected.svg';
import jobListIcon from 'public/images/icons/job-list/jobs_list.svg';
import jobListIconSelected from 'public/images/icons/job-list/jobs_list_selected.svg';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';
import { detailedViewToggleStyles } from 'components/JobsDashboard/detailed-view-toogle/detailed-view-toggle.styles';
import { IDetailedViewToggle } from './detailed-view-toogle.interface';

export const DetailedViewToggle: FC<IDetailedViewToggle> = ({
  showingDetails,
  onShowDetails,
  onCloseDetails,
  jobs,
}) => {
  const showToggle = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { t } = useTranslate();

  const handleCloseDetails = () => {
    if (showingDetails) onCloseDetails();
  };

  const handleShowDetails = () => {
    if (!showingDetails && jobs?.[0]) onShowDetails(jobs[0].id);
  };
  if (showToggle) {
    return (
      <Box sx={detailedViewToggleStyles.container}>
        {/* Details */}
        <Box mr={1}>
          <ButtonBase
            onClick={handleShowDetails}
            id="open-details-button"
            areal-label="job-details"
          >
            <Image
              src={showingDetails ? jobGridIconSelected : jobGridIcon}
              height={23}
              alt={t('img.showDetailsAlt')}
              title={t('img.showDetailsTitle')}
            />
          </ButtonBase>
        </Box>

        {/* List */}
        <Box>
          <ButtonBase
            onClick={handleCloseDetails}
            id="close-details-button"
            areal-label="job-details"
          >
            <Image
              src={showingDetails ? jobListIcon : jobListIconSelected}
              height={23}
              alt={t('img.hideDetailsAlt')}
              title={t('img.hideDetailsTitle')}
            />
          </ButtonBase>
        </Box>
      </Box>
    );
  }
  return null;
};

export default DetailedViewToggle;
