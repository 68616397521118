const deploymentURL = process.env.NEXT_PUBLIC_DEPLOYMENT_URL;

export const createShareJobUrl = (job, lang) => {
  if (!job) return '';

  const { deepLink, urlParams, isExternal } = job;

  if (deepLink) return deepLink;

  if (urlParams) {
    const { countryName, jobCategory, city, jobRole, jobId } = urlParams;

    const baseURL = lang ? `${deploymentURL}/${lang}` : deploymentURL;

    if (city && countryName && jobCategory && jobId && jobRole) {
      return `${baseURL}/${countryName}/${jobCategory}/${city}/${jobRole}/${jobId}${
        isExternal ? '-X' : ''
      }`;
    }
  }

  return '';
};

export const createJobPath = (job) => {
  if (!job) return '';

  const { urlParams } = job;

  if (urlParams) {
    const { countryName, jobCategory, city, jobRole, jobId } = urlParams;

    if (city && countryName && jobCategory && jobId && jobRole) {
      return `${countryName}/${jobCategory}/${city}/${jobRole}/${jobId}`;
    }
  }

  return '';
};
