import palette from 'theme/palette';
import shadows from 'theme/shadows';
import theme from 'theme/theme';

export const applyDiscardButtonStyles = {
  container: {
    height: '100%',
    position: 'absolute',
    top: 0,
    right: -36,
    legt: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
  left: {
    right: 'unset',
    left: -36,
  },
  button: {
    width: 72,
    height: 72,
    backgroundColor: palette.common.white,
    boxShadow: shadows[6],
    '&:hover': {
      backgroundColor: palette.common.white,
      boxShadow: shadows[9],
    },
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderRadius: 4,
    fontSize: '0.75rem',
    fontWeight: 600,
  },
};
