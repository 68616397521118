import React, { FC, memo, useCallback, useState } from 'react';
import { Box, Paper, useMediaQuery } from '@mui/material';
import classnames from 'classnames';
import theme from 'theme/theme';
import {
  CANDIDATE_JOB_STATUS,
  CANDIDATE_UNMATCH_FEEDBACKS,
} from 'constants/candidate';
import { useDislikeJob } from 'utils/swr/hooks/useDislikeJob';
import { useLikeJob } from 'utils/swr/hooks/useLikeJob';
import { useUser } from 'utils/swr/hooks/useUser';
import { useAppDispatch } from 'hooks/*';
import { openCreateAccountModal } from 'store/reducers/uiReducer';
import { JobUnmatchFeedback } from 'components/Job';
import { JobStatus } from 'components/JobsDashboard/job-status';
import { ApplyDiscardButton } from '../JobItemComponents';
import SelectedBorder from '../JobItemComponents/SelectedBorder';
import { JobItemContent } from '../job-item-content/job-item-content';
import { IJobItem } from './job-item.interface';
import { jobItemStyles, useJobItemStyles } from './job-item.styles';

export const JobItem: FC<IJobItem> = memo(
  ({
    job,
    showingDetails,
    onShowDetails,
    isSelected,
    controlsDisabled,
    logoDisabled,
    selectedBorderLeft,
  }: IJobItem) => {
    const {
      user: { isAuthed },
    } = useUser();
    const classes = useJobItemStyles();
    const isAnimated = useMediaQuery(theme.breakpoints.up('md'), {
      defaultMatches: true,
    });
    const dispatch = useAppDispatch();

    const { dislike } = useDislikeJob();
    const { like } = useLikeJob();

    const [isHovered, setIsHovered] = useState(false);
    const [isDisliking, setIsDisliking] = useState(false);
    const [isLiking, setIsLiking] = useState(false);
    const [isShowFeedback, setIsShowFeedback] = useState(false);

    const handleMouseOver = useCallback(() => {
      setIsHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
      setIsHovered(false);
    }, []);

    const handleDislike = useCallback(() => {
      setIsDisliking(true);
      const timerId = setTimeout(() => {
        dislike(job.id, null, () => setIsDisliking(false), !!job?.isExternal);
        clearTimeout(timerId);
      }, 500);
    }, [dislike, job?.isExternal]);

    const handleLike = useCallback(() => {
      if (!isAuthed) {
        dispatch(openCreateAccountModal());
      } else {
        setIsLiking(true);

        const timerId = setTimeout(() => {
          like(job.id, null, () => setIsLiking(false), job?.apply_link);
          clearTimeout(timerId);
        }, 500);
      }
    }, [like, job?.isExternal, job?.apply_link]);

    const handleStatusClick = useCallback(() => {
      if (
        job?.candidate_job_status ===
          CANDIDATE_JOB_STATUS.notMatchCandidateId &&
        job?.reason_id !== CANDIDATE_UNMATCH_FEEDBACKS.dontWantSpecify
      ) {
        setIsShowFeedback((prevState) => !prevState);
      }
    }, []);

    return (
      <Box sx={jobItemStyles.container} mb={1} component="article">
        <Paper
          className={classnames(
            classes.card,
            isSelected && classes.selectedCard,
            isLiking && classes.isLiking,
            isDisliking && classes.isDisliking,
          )}
          elevation={0}
          onClick={() => onShowDetails(job.id)}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <JobItemContent job={job} logoDisabled={logoDisabled} />

          <SelectedBorder
            isSelected={isSelected}
            matchBorderApplied={job?.candidate_job_status === 1}
            selectedBorderLeft={selectedBorderLeft}
          />

          <JobStatus
            matchRequest={job?.candidate_job_status === 1}
            noMatch={
              job?.candidate_job_status ===
              CANDIDATE_JOB_STATUS.notMatchCandidateId
            }
            profileReviewed={!!job?.candidate_job_profile_open}
            onClick={handleStatusClick}
            reasonId={job?.reason_id}
          />

          {!controlsDisabled && !showingDetails && isAnimated && isHovered && (
            <>
              <ApplyDiscardButton
                discard
                onClick={handleDislike}
                isExternal={!!job?.isExternal}
              />

              <ApplyDiscardButton
                onClick={handleLike}
                isExternal={!!job?.isExternal}
              />
            </>
          )}

          {!!(isShowFeedback && (job?.reason || job?.reason_id)) && (
            <JobUnmatchFeedback reason={job.reason} reasonId={job?.reason_id} />
          )}
        </Paper>
      </Box>
    );
  },
);

JobItem.defaultProps = {
  controlsDisabled: false,
  logoDisabled: false,
  selectedBorderLeft: false,
};

export default JobItem;
