import React, { FC, useMemo } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import { workModelsIds } from '@nploy/ui/domain';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarStar,
  faClock,
  faFileAlt,
  faMapMarkerAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import workModelImage from 'public/images/icons/work-model.svg';
import palette from 'theme/palette';
import { isNumberValid } from 'utils/helpers';
import useTranslate from 'utils/i18n/useTranslate';
import { IJobInfo } from './job-info.interface';
import { jobInfoStyles } from './job-info.styles';

export const JobInfo: FC<IJobInfo> = ({
  city,
  country,
  workModel,
  experienceYearsMax,
  experienceYearsMin,
  employmentTypes,
  employmentLevels,
  region,
  regions,
}) => {
  const { t } = useTranslate();
  const memoizedLocation = useMemo(() => {
    if (
      (workModel?.id === workModelsIds.remoteWork && region) ||
      regions?.length
    ) {
      return regions?.length
        ? `Remote - ${regions.map((r) => r.name).join(', ')}`
        : `Remote - ${region.name}`;
    }
    return `${city}, ${country}`;
  }, [regions, workModel]);
  return (
    <>
      {!!workModel && (
        <Box sx={jobInfoStyles.container}>
          <Box sx={jobInfoStyles.iconContainer}>
            <Image src={workModelImage} alt={workModel.name} />
          </Box>
          <Typography variant="body2" sx={jobInfoStyles.text}>
            {workModel.name}
          </Typography>
        </Box>
      )}
      {/* Employment types */}
      {!!employmentTypes?.length && (
        <Box sx={jobInfoStyles.container}>
          <Box sx={jobInfoStyles.iconContainer}>
            <FontAwesomeIcon
              icon={faFileAlt as IconProp}
              color={palette.darkGrey.main}
            />
          </Box>

          <Typography variant="body2" sx={jobInfoStyles.text}>
            {employmentTypes
              .map(({ name }) => t(`job.employmentTypes.${name}`))
              .join(' / ')}
          </Typography>
        </Box>
      )}
      {/* Experience */}
      {!!(isNumberValid(experienceYearsMin) && experienceYearsMax) && (
        <Box sx={jobInfoStyles.container}>
          <Box sx={jobInfoStyles.iconContainer}>
            <FontAwesomeIcon
              icon={faCalendarStar as IconProp}
              color={palette.darkGrey.main}
            />
          </Box>

          <Typography variant="body2" sx={jobInfoStyles.text}>
            {experienceYearsMin !== experienceYearsMax
              ? `${experienceYearsMin} - ${experienceYearsMax}`
              : experienceYearsMin}{' '}
            {experienceYearsMin === experienceYearsMax &&
            experienceYearsMin === 1
              ? t('job.year')
              : t('job.years')}{' '}
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              sx={jobInfoStyles.textSecondary}
            >
              {t('job.ofExperience')}
            </Typography>
          </Typography>
        </Box>
      )}

      {/* Employment levels */}
      <Box sx={jobInfoStyles.container}>
        <Box sx={jobInfoStyles.iconContainer}>
          <FontAwesomeIcon
            icon={faClock as IconProp}
            color={palette.darkGrey.main}
          />
        </Box>

        <Typography variant="body2" sx={jobInfoStyles.text}>
          {employmentLevels.map(({ name }) => name).join(' / ')}
        </Typography>
      </Box>

      {/* Location */}
      <Box sx={jobInfoStyles.container}>
        <Box sx={jobInfoStyles.iconContainer}>
          <FontAwesomeIcon
            icon={faMapMarkerAlt as IconProp}
            color={palette.darkGrey.main}
          />
        </Box>

        <Typography variant="body2" sx={jobInfoStyles.text}>
          {memoizedLocation}
        </Typography>
      </Box>
    </>
  );
};

export default JobInfo;
