import theme from 'theme/theme';

export const noJobsStyles = {
  container: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(13),
  },
  noJobsIcon: {
    marginBottom: theme.spacing(4),
  },
  noMatchingText: {
    fontSize: '1.375rem',
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  bodyText: {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: theme.palette.common.black,
  },
};
